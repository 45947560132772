<template>
    <div>
        <img :src="imgUrl" alt="">
    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
export default {
    setup() {
        const imgUrl = ref('')

        let listStr = sessionStorage.getItem('business')
        let list = listStr?JSON.parse(listStr):[]
        for (const el of list) {
            if(el.coreType === 0) {
                imgUrl.value = el.imgUrl
            }
        }

        return {
            imgUrl
        }
    },
}
</script>
<style lang="scss" scoped>
    img {
        width: 100%;
        height: 100%;
        vertical-align: top;
        margin-top: 10px;
        margin-bottom: 50px;
    }
</style>